import React from "react";
import "./Home.css";

const Home = () => {
  const isMobile = window.matchMedia("(max-width:600px)").matches;
  return (
    <div className='HomeContainer'>
      <div className='Text'>Herzlich Willkommen bei der Baumschule Helms!</div>
      {/*       <div className='ClosedNote'>
        Auf Grund der Ferienzeit geänderte Öffnungszeiten vom 03.07. - 18.08.23:
        <br />
        Mo - Fr 8:00 - 12:30 Uhr
      </div> */}
{/*       {
        <div className='ClosedNote'>
          Zusätzliche Öffnungszeiten anlässlich des Weihnachtsbaumverkaufs am
          09.12. sowie 16 + 17.12. und am 23.12.
          <br />
          10:00 - 16:00 Uhr
        </div>
      } */}
      <img className='Image' src='home.jpeg' />
      <div className='Text'>
        Öffnungszeiten: {isMobile && <br />} Mo - Fr 8:00 - 12:30 Uhr 14:00 -
        17:00 Uhr
      </div>
    </div>
  );
};

export default Home;
